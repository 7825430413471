/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 * - /npm/sweetalert2@7.33.1/dist/sweetalert2.all.min.js
 * - /npm/toastr@2.1.4/toastr.min.js
 * - /npm/pace-js@1.2.4/pace.min.js
 * - /npm/vue-select@2.6.4/dist/vue-select.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
